export class ModalOpenAttributeEvent {
    static bind(button)
    {
        button.addEventListener('click', async (e) => {

            e.preventDefault();

            let url = button.dataset.modalOpen;

            if (button.hasAttribute('data-item-id')) {
                url = url + button.getAttribute('data-item-id') + '/';
            }

            const {modal} = await import('../Library/Modal.js');

            modal.open({
                modalClass: button.dataset.modalClass ? button.dataset.modalClass : null,
                customTemplate: button.dataset.customTemplate ? button.dataset.customTemplate : null,
                itemId: button.dataset.itemId ? button.dataset.itemId : null,
                contentAjaxUrl: url,
                onOpen: (modal) => {
                    window.bo.events.bindAll(modal.elm);
                },
                onClose: () => {
                    if (typeof button.dataset.modalOncloseRefresh !== 'undefined') {
                        location.reload(true);
                    }
                }
            });
        });
    }
}